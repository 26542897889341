.CurrentWeather {
    display: flex;
    justify-content: center;
    width: 100%;
}

.current-weather-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin: 100px 10px 0px 10px;
    border-radius: 5px;
    width: 100%;
    color: #fff;
    background: linear-gradient(-360deg, #000, #29bef5);
}

.current-weather-info {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px
}

.temp-desc {
    display: flex;
    flex-direction: column;
}

.temp-desc h2 {
    font-size: 3em;
}

.temp-desc h3 {
    font-size: 1.5em;
}

.temp-desc h4:nth-child(2) {
    font-size: 1.4em;
}

.temp-desc h4:nth-child(3) {
    margin: 5px 0px 10px 0px;
    font-size: 1.3em;
}

.current-weather-info img {
    width: auto;
    height: 100%;
}

.current-weather-icon {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 10px;
    width: 30%;
    height: auto;
    border: 1px solid #fff;
    border-radius: 100%;
    box-shadow: 1px 3px 3px 0px inset #fff;
    padding: 5px
}

/*////// Media Queries //////*/

/* Mobile */
@media only screen and (min-width: 450px) {

}

@media(max-width: 600px) and (min-width: 450px) {
    .current-weather-container {
    }
}

@media(max-width: 768px) and (min-width: 600px){
   .current-weather-container {

   }
}

/* Tablet */
@media(max-width: 1024px) and (min-width: 768px) {
    .current-weather-container {
        width: 60%;
    }
}

/* laptop */
@media(max-width: 1366px) and (min-width: 1024px) {
    .current-weather-container {
        width: 60%;
    }
}

/* Desktop */
@media(min-width: 1366px) {
    .current-weather-container {
        width: 40%;
    }
    
}


