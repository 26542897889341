.News {}

.news-container {
    justify-content: center;
    align-items: center;
    color: #fff;
}

.news-title-container {
    display: flex;
    align-items: center;
    box-sizing: border-box;
    padding: 25px;
    background: linear-gradient(180deg,#29bef5, #000);
    box-shadow: 3px 3px 3px #000;
    border-radius: 5px;
    margin: 30px 10px;
}

.news-title-container span {
    font-size: 1.5em;
    color: #fff;
    margin-right: 10px;
}



.News h3 {
    color: #fff;
    font-size: 1.3em;
}

.news-data {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.article-container {
    background-color: #2b2b2b;
    box-shadow: 3px 3px 3px #000;
    border-radius: 5px;
    margin: 0px 10px 10px 10px;
}

.article-header {
    box-sizing: border-box;
    width: 100%;
    display: flex;
    justify-content: space-between;
    background: linear-gradient(180deg,#29bef5, #000);
    border-radius: 5px 5px 0 0;
    padding: 20px;
}
.article-container:nth-child(4) ~ * {
    display: none;
}

.article-container img {
    width: 100%;
    height: 50%;
}

.article-footer {
    padding: 15px;
}

.image-footer {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #000746;
    width: 100%;
    height: 7px;
}

.alternate-container {
    box-sizing: border-box;
    width: 100%;
    height: auto;
    font-size: 0;
}

.image-footer p {
    color: #fff;
    font-size: 0.4rem;
}

.news-loading-container {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px;
}
/*/
///// Media Queries //////*/

/* Mobile */
@media only screen and (min-width: 767px) {}

/* Tablet */
@media(max-width: 1024px) and (min-width: 768px) {
    .news-container h3 {
        font-size: 2em;
    }
    
    .news-title-container {
        width: 80%;
        margin: 10px auto;
    }

    .article-container {
        margin: 10px;
        width: 40%;
        min-height: 300px;
        max-height: 300px;
        box-shadow: 3px 3px 3px #000;
    }
    .article-container:nth-child(4) ~ * {
        display: block;
    }
    .news-data {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        align-items: flex-start;
    }

    .article-footer > h4 {
        font-size: 1em;
    }

    .news-data img {
        width: 100%;
        min-height: 150px;
        max-height: 150px;
    }

}

/* laptop */
@media(max-width: 1366px) and (min-width: 1024px) {

    .news-container {
      margin: 0px auto;
    }

    .News h3 {
        font-size: 1.5em;
        width: 80%;
    }

    .news-title-container {
        width: 90%;
        margin: 20px auto;
    }
    
    .news-container h3 {
        font-size: 2em;
    }

    .article-container {
        margin: 20px;
        width: 28%;
        max-height: 320px;
        min-height: 320px;
        box-shadow: 3px 3px 3px #000;
    }

    .article-container:nth-child(4) ~ * {
        display: block;
    }
    .article-footer {
        padding: 20px;
    }
    .news-data {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        align-items: flex-start;
    }
    .news-data h4:nth-child(3) {
        padding: 5px;
    }

    .news-data img {
        width: 100%;
        height: 150px;
    }
    .alternate-container img {
        width: 100%;
    }
    .article-footer > h4 {
      font-size: 1em;
  }
}

/* Desktop */
@media(min-width: 1366px) {
    .News h3 {
        font-size: 1.5em;
    }
    
    .news-title-container {
        width: 75%;
        margin: 20px auto;
    }

    .news-container h3 {
        font-size: 2em;
    }
    .article-container {
        box-shadow: 3px 3px 3px #000;
        width: 30%;
        height: 300px;
    }
    .article-container:hover {
        cursor: pointer;
    }
    .article-container:nth-child(4) ~ * {
        display: block;
    }
    .news-data {
        width: 80%;
        margin: 0px auto;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        align-items: flex-start;
    }
    .news-data img {
        width: 100%;
        min-height: 100px;
        max-height: 100px;
    }
    .alternate-container img {
        width: 100%;
    }
    .article-footer > h4 {
      font-size: 1em;
  }
}
