* {
    margin: 0;
    padding: 0;
}
body {
    background-color: #171717;
}

html {
    scroll-behavior: smooth;
}

/*////// Main App Loading //////*/
.loader-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
}

.main-loader-container{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background: linear-gradient(180deg,#29bef5, #000);
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
}

.main-loader-container h2 {
    color: #fff;
    margin-top: 30px;
    border-radius: 10px;
    padding: 10px;
}

.main-loader-container svg {
    padding: 20px; 
}

/*////// Media Queries //////*/

/* Mobile */
@media screen and (max-width: 767px) {

}

/* Tablet */
@media(max-width: 1024px) and (min-width: 768px) {
   body {
    /* background-color: #1698ee; */
   }
   .desktop-view {
       margin: 10px 5% 0px 5%;
       display: flex;
       align-items: baseline;
    }
}

/* laptop */
@media(max-width: 1366px) and (min-width: 1024px) {
   .desktop-view {
    margin: 10px 5% 0px 5%;
    display: flex;
    justify-content: space-around;
    align-items: baseline;
}
}

/* Desktop */
@media(min-width: 1366px) {
   .desktop-view {
    margin: 10px 10% 0px 10%;
    display: flex;
    justify-content: space-around;
    align-items: baseline;
}
}






