
nav {
    display: flex;
    position: fixed;
    width: 100%;
    z-index: 1000;
    top: 0;
    background-color: #2b2b2b;
    box-shadow: 0 0 3px 3px #000;
    border-top: 1px solid #29bef5;
    border-bottom: 1px solid #29bef5;
}

nav ul {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    list-style-type: none;
    padding: 15px;
}

nav ul li {
    font-size: 1.1em;
}

nav ul li:nth-child(1) {
    font-size: 1.2em;
    color: #fff;

}

nav ul li:nth-child(2) {
    display: flex;
    align-items: center;
    color: #fff;
}

nav ul li:nth-child(2) span{
    color: #29bef5;
    margin-right: 5px;
}

nav ul li:nth-child(3) {
    color: #fff;
}

.location-info {
    margin: 0 20px;
    box-shadow: 0 0 1px 1px inset #fff;
    border-radius: 5px;
}

.location-info-mobile {
    box-shadow: 0 0 1px 1px inset #fff;
    color: #fff;
    border-radius: 5px;
    margin: 0 10px;
    width: 200px;
    padding: 3px;
}

.settings-container {
    position: fixed;
    top: 57px;
    left: 0px;
    width: 300px;
    height: 60px;
    border-radius: 0px 0px 5px 0px;
    box-shadow: 3px 3px 3px #000;
    z-index: 999;
    background-color: #2b2b2b;
    color: #fff;
    display: flex;
    align-items: center;
    animation: settingsSlideOpen 300ms ease-in;
}

@keyframes settingsSlideOpen {
    0% {
        transform: translateY(-65px);
    }
    100% {
        transform: translateY(0);
    }
}

.settings-container label {
    font-size: 1.3em;
}
.settings-container div {
    display: flex;
    margin-left: 10px;
    width: 300px;
}

.settings-container div:nth-child(1) {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 20%;
    padding: 10px;
}

.settings-container span {
  margin-left: 5px;
  font-size: 1.3em;
}

.settings-container span:nth-child(4) {
    margin-right: 10px;
    font-size: 1em;
}

.settings-container div:nth-child(2) {
   display: flex;
   justify-content: center;
   align-items: center;
   width: 40%;
   padding: 10px;
}

.settings-container img {
    padding: 0px;
    margin: 0px;
    width: 50px;
    height: 50px;
}

/*///// Media Queries //////*/

/* Mobile */
@media screen and (max-width: 767px) {
    .location-info {
        display: none;
    }
    .location-info-mobile {
        display: block;
    }

}

/* Tablet */
@media(max-width: 1024px) and (min-width: 768px) {

    .Nav {
        font-size: .8em;
    }
    .location-info-mobile {
        display: none;
    }
    .location-info {
        box-shadow: none;
        width: auto;
        letter-spacing: 2px;
        font-weight: 600;
    }
    .Nav ul li {
        font-size: 1.3em;
    }
}

/* laptop */
@media(max-width: 1366px) and (min-width: 1024px) {
    .location-info-mobile {
        display: none;
    }
    .location-info {
        box-shadow: none;
        width: auto;
        letter-spacing: 2px;
        font-weight: 600;
    }
    .Nav ul li {
        font-size: 1em;
    }
}

/* Desktop */
@media(min-width: 1366px) {
    .location-info-mobile {
        display: none;
    }
    .location-info {
        box-shadow: none;
        width: auto;
        letter-spacing: 2px;
        font-weight: 600;
    }
    .Nav ul li {
        font-size: 1em;
    }.current-weather-container {
        padding: 5px;
    }
}
