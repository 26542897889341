.Footer {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 30px;
  border-top: 1px solid #29bef5;
  background-color: #2b2b2b;;
  padding: 10px;
}

.Footer > span {
  color: #29bef5;
  font-family: 'MonteCarlo';
  font-weight: 600;
  letter-spacing: 3.5px;
  font-size: 1em;
}

/*///// Media Queries //////*/

/* Mobile */
@media screen and (max-width: 767px) {}

/* Tablet */
@media(max-width: 1024px) and (min-width: 768px) {
  .Footer > span {
    font-size: 2em;
  }
}

/* laptop */
@media(max-width: 1366px) and (min-width: 1024px) {
  .Footer > span {
    font-size: 2em;
  }
}

/* Desktop */
@media(min-width: 1366px) {
  .Footer > span {
    font-size: 2em;
  }
}