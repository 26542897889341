.FiveDay {
  
}

.FiveDay h3 {
    display: flex;
    color: #fff;
    padding: 15px;
    background: linear-gradient(180deg,#29bef5, #000);
    border-radius: 5px 5px 0px 0px;
}

.five-day-container {
    color: #fff;
    box-shadow: 3px 3px 3px #000;
    background-color: #2b2b2b;
    border-radius: 5px;
    margin: 20px 10px 10px 10px;
}

.five-day-info {
    display: flex;
    justify-content: center;
    align-self: center;
  }

.five-day-container ul {
    display: flex;
    justify-content: space-around;
    align-items: center;
    text-decoration: none;
    margin-top: 10px;
    padding: 5px;
}

.five-day-container ul li {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
}

.five-day-container h5 {
    font-size: 1em;
}

.five-day-container span {
    font-size: 1em;
}

.five-day-container span:nth-child(4) {
    margin-bottom: 10px;
}


.five-day-container ul li img {
    width: 100%;
    height: auto;
}

/*////// Media Queries //////*/

/* Mobile */
@media only screen and (max-width: 767px) {

}

/* Tablet */
@media(max-width: 1024px) and (min-width: 768px) {
  .FiveDay {
    width: 60%;
  }
  .five-day-container {
    box-shadow: 3px 3px 3px #000;
  }
  .FiveDay h3 {
    
  }
  .five-day-container {
    height: 325px;
  }
  .five-day-container ul {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 40px;
  }
  .five-day-container h3 {
    font-size: 1.5em;
  }

  .five-day-container h5 {
    font-size: 1.2em;
  } 
  .five-day-container ul li span {
    font-size: 1.2em;
  }
}

/* laptop */
@media(max-width: 1366px) and (min-width: 1024px) {
  .FiveDay {
    width: 60%;
  }
  .five-day-container {
    box-shadow: 3px 3px 3px #000;
    height: 325px;
  }
  .five-day-container ul {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin: 0 auto;
    margin-top: 25px;
  }
  .five-day-container h3 {
    font-size: 1.5em;
  }

  .five-day-container h5 {
    font-size: 1.2em;
  } 
  .five-day-container ul li span {
    font-size: 1.2em;
  }
}

/* Desktop */
@media(min-width: 1366px) {
  .FiveDay {
    width: 60%;
  }
  .five-day-container {
    box-shadow: 3px 3px 3px #000;
    height: 325px;
  }
  .five-day-container ul {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin: 0 auto;
    margin-top: 10px;
  }
  .five-day-container ul li {
    margin-right: 50px;
  }
  .five-day-container h3 {
    font-size: 1.5em;
  }

  .five-day-container h5 {
    font-size: 1.2em;
  } 
  .five-day-container ul li span {
    font-size: 1.2em;
  }
}
