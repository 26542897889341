/*///// Search Container //////*/
.SearchModal {
  position: fixed;
  z-index: 999;
  top: 55px;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
  background-color: #2b2b2b;
  box-shadow: 0px 3px #000;
}

.SearchModal h3 {
  color: #fff;
}

.SearchModal h3:nth-child(2) {
  padding: 10px 0px 5px 10px;
}

.SearchModal h4 {
  padding: 10px;
}

.search-container {
  display: flex;
  justify-content: space-between;
  background-color: #2b2b2b;
  align-items: center;
  border-radius: 5px 5px 0px 0px;
  padding: 15px;
}

.search-container > span {
  display: flex;
  color: #fff;
  font-size: 1em;
}

.search-input {
  width: 85%;
  display: flex;
  align-items: center;
}

.search-input span {
  padding: 4.8px;
  border: 1px solid #29bef5;
  border-radius: 5px 0px 0px 5px;
}

.search-input > input {
  border: none;
  padding: 5px;
  font-size: 1em;
  border-top: 1px solid #29bef5;
  border-right: 1px solid #29bef5;
  border-bottom: 1px solid #29bef5;
  background: #2b2b2b;
  color: #fff;
  width: 100%;
  border-radius: 0px 5px 5px 0px;
}

.search-input input::placeholder {
  padding-left: 5px;
  color: #fff;
}

.search-input > input:focus {
  outline: none !important;
  color: #fff;
}

.search-input span {
  color: #fff;
}

/*///// Current Location /////*/

.current-location-container {
  margin-top: 10px;
  padding-bottom: 20px;
  color: #fff
}

.current-location-container h3 {
  font-size: 1em;
  padding: 10px;
}

.current-location-container div {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0px 15px 0px 15px;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 10px;
  padding: 10px;
  background: linear-gradient(180deg,#29bef5, #000);
  display: flex;
  align-items: center;
}

.current-location-container span {
  font-size: 1.3em;
  margin-left: 5px;
}

.current-location-container h4 {
  font-size: 1em;
}


/*//// City Search List //// */
 
.city-list-container {
  display: flex;
  flex-direction: column;
  position: fixed;
  box-sizing: border-box;
  z-index: 1000;
  top: 60px;
  left: 50%;
  width: 100%;
  height: calc(100% - 60px);
  transform: translateX(-50%);
  background-color: #2b2b2b;
  border-radius: 0px 0px 5px 5px;
}


.city-list-header-searching {
  display: flex;
  align-items: center;
  padding: 5px;
  margin-bottom: 5px;
}

.city-list-header-searching svg {
  margin-left: 5px;
  width: 30px;
  height: 30px;
}

.city-list-header-searching > h3 {
  font-size: 1.4em;
}

.city-list-header {
  display: flex;
  align-items: center;
  justify-content: space-around;
  padding: 5px;
  margin-bottom: 5px;
}


.city-list-header button {
  background-color: #29bef5;
  box-shadow: 0px 2px 2px #000;
  border: none;
  color: #fff;
  font-size: .8em;
  letter-spacing: .1em;
  font-weight: 800;
  border-radius: 5px;
  width: 75px;
  padding: 5px;
  cursor: pointer;
}


.city-list-items {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  border: 1px solid #29bef5;
  border-radius: 5px;
  margin: 0px 15px 10px 15px;
  padding: 5px;
  color: #fff;
  font-weight: 500;
}

.city-list-items > span {
  margin-left: 5px;
}

/*///// Recent Search /////*/

.recent-search-container {
  display: flex;
  flex-direction: column;
  height: 275px;
  overflow: scroll;
  scrollbar-width: none;
  margin: 5px 0px 20px 0px;
}

.recent-search-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.recent-search-header h3 {
  padding: 0px 0px 5px 10px;
}

.recent-search-header button {
  background-color: #29bef5;
  border: none;
  color: #fff;
  font-size: .8em;
  letter-spacing: .1em;
  font-weight: 800;
  border-radius: 5px;
  width: 75px;
  margin-right: 20px;
  padding: 5px;
  cursor: pointer;
  box-shadow: 0px 2px 2px #000;
}

.recent-search-container::-webkit-scrollbar {
  display: none;
}

.recent-search-city {
  display: flex;
  align-items: center;
  cursor: pointer;
  margin: 10px 15px 0px 15px;
  border-radius: 5px;
  padding: 3px;
  border: 1px solid #29bef5;
  color: #fff;
}

.recent-search-city span {
  margin-left: 5px;
}


/*///// Media Queries /////*/

/* Mobile */
@media screen and (min-width: 767px) {

}

/* Tablet */
@media(max-width: 1024px) and (min-width: 768px) {
  .SearchModal {
    width: 60%;
  }
}

/* laptop */
@media(max-width: 1366px) and (min-width: 1024px) {
  .SearchModal {
    width: 50%;
  }
  
}

/* Desktop */
@media(min-width: 1366px) {
 .SearchModal {
  width: 50%;
 }
}