.ArticleModal {
    position: fixed;
    z-index: 1000;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    height: auto;
    width: 95%;
    color: #fff;
    border-radius: 5px;
    box-shadow: 0px 0px 50px 20px #000;
    background-color: #2b2b2b;
    animation:  ArticleModal 200ms forwards ;
}

@keyframes ArticleModal {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
    
}

.article-modal-header {
    background: linear-gradient(180deg,#29bef5, #000);
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 5px 5px 0px 0px;
    width: 100%;
    padding: 15px;
}

.article-content img {
    width: 100%;
    height: auto;
    margin-bottom: 5px;
}

.article-info {
  padding: 10px;
}

.article-info > div {
    display: flex;
    justify-content: space-between;
}

.article-info > div h5 {
    font-size: .8em;
}

.article-info p {
    margin: 15px 0px 20px 0px;
    text-indent: .7em;
    font-size: 1.3em;
}

.article-info a {
    color: #fff;
}

/*////// Media Queries //////*/

/* Mobile */
@media only screen and (min-width: 767) {

}

/* Tablet */
@media(max-width: 1024px) and (min-width: 768px) {
  .ArticleModal {
      width: 70%;
  }
}

/* laptop */
@media(max-width: 1366px) and (min-width: 1024px) {
    .ArticleModal {
        width: 50%;
    }
}

/* Desktop */
@media(min-width: 1366px) {
    .ArticleModal {
        width: 50%;
    }
}

