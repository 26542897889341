.DailyDetails {

}


.daily-details-container {
    display: flex;
    flex-direction: column;
    background-color: #2b2b2b;
    color: #fff;
    margin: 20px 10px 10px 10px;
    border-radius: 5px;
    box-shadow: 3px 3px 3px #000;
    height: auto;
}

.daily-details-container h3 {
    color: #fff;
    padding: 15px;
    background: linear-gradient(180deg,#29bef5, #000);
    border-radius: 5px 5px 0px 0px;
}


.DailyDetails ul {
  list-style-type: none;
  display: flex;
  flex-direction: column;
  padding: 5px 20px 20px 20px;
  width: 70%;
  margin: 0px auto;
}

.DailyDetails ul li {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #fff;
  padding: 13px;
}

.DailyDetails ul li label {
  
}

.DailyDetails ul li img {
  width: 40px;
  height: 40px;
}

.daily-details-data {
  display: flex;
  justify-content: flex-end;  
}

/*////// Media Queries //////*/

/* Mobile */
@media only screen and (max-width: 767px) {

}

/* Tablet */
@media(max-width: 1024px) and (min-width: 768px) {
  .DailyDetails {
    width: 40%;
  }
  .daily-details-container {
    box-shadow: 3px 3px 3px #000;
    height: 325px;
  }
  .daily-details-container h3 {
    font-size: 1.5em;
  }
  .daily-details-container ul li {
    font-size: 1em;
  }
}

/* laptop */
@media(max-width: 1366px) and (min-width: 1024px) {
  .DailyDetails {
    width: 40%;
  }
  .daily-details-container {
   box-shadow: 3px 3px 3px #000;
   height: 325px;
  }
  .daily-details-container h3 {
    font-size: 1.5em;
  }
  .daily-details-container ul li {
    font-size: 1em;
  }
}

/* Desktop */
@media(min-width: 1366px) {
  .DailyDetails {
    width: 40%;
  }
  .daily-details-container {
   box-shadow: 3px 3px 3px #000;
   height: 325px;
  }
  .daily-details-container h3 {
    font-size: 1.5em;
  }
  .daily-details-container ul li {
    font-size: 1em;
  }
}



